import React from 'react';
import ReactDOM from 'react-dom';
import WebFont from 'webfontloader';
import { addToSDK, initializeOptions } from 'utils/sdkBuilder';
import { AppProvider } from 'context';
import { App } from './App';

function initialize(options: initializeOptions) {
  if (!options?.enterpriseId) {
    // eslint-disable-next-line no-console
    console.error('invalid options');
    return;
  }

  if (window.frameElement?.id !== 'iframe-oli') {
    WebFont.load({
      google: {
        families: ['Poppins:300,400,500,600,700'],
      },
    });

    const div = document.createElement('div');
    div.id = 'oli-root';
    document.body.appendChild(div);

    ReactDOM.render(
      <React.StrictMode>
        <AppProvider>
          <App enterpriseId={options.enterpriseId} />
        </AppProvider>
      </React.StrictMode>,
      document.getElementById('oli-root'),
    );
  }
}

addToSDK({ initialize });

// if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
// }
initialize({ enterpriseId: 'teste' });
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
if (oli.open) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  oli.open();
}
