import { InputNumber } from 'components/atoms/InputNumber';
import { Text } from 'components/atoms/Text';
import { Container } from './styles';

export const ProductQuantity = () => {
  return (
    <Container>
      <Text>Quantidade</Text>
      <InputNumber />
    </Container>
  );
};
