import ReactDOM from 'react-dom';
import { AnimatePresence } from 'framer-motion';
import { useConfig } from 'context/Configs';
import { Main } from 'screens/Main';
import { useWindowSize } from 'hooks';
import { GlobalStyle } from 'styles/global';
import { useEffect, useRef } from 'react';
import { initializeOptions } from 'utils/sdkBuilder';
import { Pip } from './components/organisms/Pip';

const Iframe = () => {
  const { height, width } = useWindowSize();
  const iframe = useRef<HTMLIFrameElement>(null);
  const firstLoad = useRef(true);

  useEffect(() => {
    if (firstLoad.current) {
      firstLoad.current = false;
      if (iframe.current) {
        iframe.current.srcdoc = document.documentElement.innerHTML;
      }
      const elementsToBeDeleted = document.body.querySelectorAll('body > *');
      elementsToBeDeleted.forEach((element) => {
        if (!['iframe-oli', 'oli-root', 'oli-sheet'].includes(element.id)) {
          element.remove();
        }
      });
    }
  }, []);

  return ReactDOM.createPortal(
    <iframe
      ref={iframe}
      id="iframe-oli"
      title="iframe-oli"
      srcDoc=""
      frameBorder="0"
      style={{ width, height }}
    />,
    document.body,
  );
};

export const App = ({ enterpriseId }: initializeOptions) => {
  const { configData, isOpen, setEnterpriseId } = useConfig();

  useEffect(() => {
    setEnterpriseId(enterpriseId);
  }, [enterpriseId, setEnterpriseId]);
  return (
    <>
      <GlobalStyle />
      <Iframe />
      <AnimatePresence exitBeforeEnter>
        {isOpen && (configData.isPip ? <Pip /> : <Main />)}
      </AnimatePresence>
    </>
  );
};
