import { BagBlackIcon } from 'assets/icons';
import styled from 'styled-components';

export const EmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const BagBlackIconStyled = styled(BagBlackIcon)`
  width: 27.3px;
  height: 30.33px;
  margin-bottom: 25px;
`;
