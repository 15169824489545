import { gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { IUser } from 'types/user';

export type CreateUserVars = {
  data: IUser;
};

export type CreateUserResponse = {
  createUserLive: IUser;
};

export const useMutationsUsers = () => {
  const CREATE_USER = gql`
    mutation CreateUser($data: newUserLive!) {
      createUserLive(data: $data) {
        id
        firstName
        lastName
        phone
        email
        liveId
        acceptTerms
        acceptPolicy
        status
        ip
      }
    }
  `;

  const [addUserBase] = useMutation<CreateUserResponse, CreateUserVars>(
    CREATE_USER,
  );

  const addUser = useCallback(
    async ({ data }: CreateUserVars) => {
      const resp = await addUserBase({ variables: { data } });
      return resp.data?.createUserLive;
    },
    [addUserBase],
  );

  return { addUser };
};
