import styled from 'styled-components';
import { AudioOffIcon, AudioOnIcon, CloseIcon } from 'assets/icons';
import { motion } from 'framer-motion';

export const Container = styled.header`
  display: flex;
  align-items: flex-start;
  z-index: 1;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: column;

  margin-left: auto;
  > svg {
    margin-bottom: 10px;
    cursor: pointer;
  }
`;

export const RecIcon = styled(motion.div)`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 5px;
  background: #e81a3f;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
`;

export const AudioOffIconStyled = styled(AudioOffIcon)`
  height: 20px;
  width: 20px;
  margin-top: 5px;
`;

export const AudioOnIconStyled = styled(AudioOnIcon)`
  height: 20px;
  width: 20px;
  margin-top: 5px;
`;

export const CloseIconStyled = styled(CloseIcon)`
  height: 15px;
  width: 15px;
`;
