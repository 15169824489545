import { ReactNode } from 'react';
import { Container, Image } from './styles';

type AvatarProps = {
  children?: ReactNode;
  src?: string;
  size: number;
};

export const Avatar = ({ children, src, size }: AvatarProps) => {
  return (
    <Container size={size} src={src}>
      {src && <Image src={src} />}
      {children}
    </Container>
  );
};
