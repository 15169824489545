import { LikeIcon } from 'assets/icons';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const LikeIconStyled = styled(LikeIcon)`
  width: 22.62px;
  height: 19.1px;
  cursor: pointer;
`;
