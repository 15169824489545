import { CatalogIcon, LikeIcon, ShareIcon } from 'assets/icons';
import styled from 'styled-components';

export const Container = styled.main`
  z-index: 1;
  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    gap: 20px;
  }
`;

export const CatalogIconStyled = styled(CatalogIcon)`
  width: 22.38px;
  height: 21.9px;
  cursor: pointer;
`;

export const ShareIconStyled = styled(ShareIcon)`
  width: 22.62px;
  height: 19.1px;
  cursor: pointer;
`;

export const LikeIconStyled = styled(LikeIcon)`
  width: 22.62px;
  height: 19.1px;
  cursor: pointer;
`;

export const ChatContainer = styled.article`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  max-height: 20vh;
  overflow: auto;
  justify-content: flex-start;
  align-items: flex-start;

  ::-webkit-scrollbar {
    width: 13px;
    height: 13px;
  }
  ::-webkit-scrollbar-thumb {
    background: #b3afb3;
    border-radius: 30px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #b3afb3;
  }
  ::-webkit-scrollbar-track {
    border-radius: 0px;
    box-shadow: inset 0px 0px 0px 0px #f0f0f0;
  }
`;
