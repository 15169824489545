import { Text } from 'components/atoms/Text';
import { AnimatePresence, motion } from 'framer-motion';
import { useDrawer } from 'context/Drawer';
import { useProduct } from 'context/Products';

import { Card, Container } from './styles';

export const ProductSlider = () => {
  const { openDrawer } = useDrawer();
  const { productsDisplayed, setCurrentProduct } = useProduct();

  return (
    <Container>
      <AnimatePresence presenceAffectsLayout>
        {productsDisplayed.map((value) => (
          <motion.div
            key={value.product.id}
            initial={{ x: 300, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <Card
              onClick={() => {
                setCurrentProduct(value);
                openDrawer('ProductDrawer');
              }}
            >
              <img
                src={value.product.image}
                alt="Imagem Produto"
                style={{ width: '100%' }}
              />
              <div>
                <Text
                  component="h2"
                  weight={500}
                  size={12}
                  gutterBottom={7}
                  title={value.product.name}
                >
                  {value.product.name}
                </Text>
                <Text component="span" weight={700} size={16}>
                  R$ {value.product.price}
                </Text>
              </div>
            </Card>
          </motion.div>
        ))}
      </AnimatePresence>
    </Container>
  );
};
