import { Button } from 'components/atoms/Button';
import { Drawer } from 'components/molecules/Drawer';
import { Text } from 'components/atoms/Text';
import { useDrawer } from 'context/Drawer';
import { useUser } from 'context/User';
import { ChangeEvent, FormEvent, useState } from 'react';
import { useConfig } from 'context/Configs';
import { TextField } from 'components/atoms/TextField';
import { Container } from './styles';

type iFormData = {
  name: string;
  email: string;
  phone: string;
};

export const UserFormDrawer = () => {
  const { isThisDrawer, closeDrawers } = useDrawer();
  const { user, createUser } = useUser();
  const { configData } = useConfig();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState<iFormData>({
    name: '',
    email: '',
    phone: '',
  });

  const handleName = (event: ChangeEvent<HTMLInputElement>) => {
    setFormData((state) => ({ ...state, name: event.target.value }));
  };

  const handleEmail = (event: ChangeEvent<HTMLInputElement>) => {
    setFormData((state) => ({ ...state, email: event.target.value }));
  };

  const handlePhone = (event: ChangeEvent<HTMLInputElement>) => {
    setFormData((state) => {
      let phone = event.target.value.replace(/\D/g, '');
      phone = phone.replace(/^0/, '');
      if (phone.length === 0) {
        phone = '';
      } else if (phone.length > 10) {
        phone = phone.replace(/^(\d\d)(\d{5})(\d{4}).*/, '($1) $2-$3');
      } else if (phone.length > 6) {
        phone = phone.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, '($1) $2-$3');
      } else if (phone.length > 2) {
        phone = phone.replace(/^(\d\d)(\d{0,5})/, '($1) $2');
      } else {
        phone = phone.replace(/^(\d*)/, '($1');
      }
      return { ...state, phone };
    });
  };

  const handlePhoneBlur = () => {
    if (formData.phone.length < 14) {
      setFormData((state) => ({ ...state, phone: '' }));
    }
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    const resp = await fetch('https://api.ipify.org/?format=json');
    const { ip } = await resp.json();
    const nameArray = formData.name.split(' ');
    createUser({
      firstName: nameArray[0],
      lastName:
        nameArray.length - 1 !== 0 ? nameArray[nameArray.length - 1] : '',
      email: formData.email,
      phone: formData.phone,
      acceptPolicy: true,
      acceptTerms: true,
      liveId: configData.liveId,
      ip,
    });
  };

  return (
    <Drawer
      isOpen={user ? false : isThisDrawer('UserFormDrawer')}
      onClose={() => {
        closeDrawers();
      }}
      fitContent
    >
      <Container onSubmit={handleSubmit}>
        <Text weight={500} size={14}>
          Digite seus dados para interagir
        </Text>

        <TextField
          name="name"
          label="Nome"
          required
          value={formData.name}
          onChange={handleName}
        />

        <TextField
          type="email"
          name="email"
          label="E-mail"
          required
          value={formData.email}
          onChange={handleEmail}
        />

        <TextField
          type="tel"
          name="phone"
          label="Telefone"
          value={formData.phone}
          onChange={handlePhone}
          onBlur={handlePhoneBlur}
        />

        <Button type="submit" color="primary" disabled={loading}>
          Começar a interagir
        </Button>
        <Text weight={500} size={9} align="center">
          Clicando em “Continuar” você concorda com os Termos de serviços e
          Política de Piovacidade
        </Text>
      </Container>
    </Drawer>
  );
};
