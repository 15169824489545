import { BagDrawer } from 'components/organisms/BagDrawer';
import { CatalogDrawer } from 'components/organisms/CatalogDrawer';
import { OptionsDrawer } from 'components/organisms/OptionsDrawer';
import { ProductDrawer } from 'components/organisms/ProductDrawer';
import { UserFormDrawer } from 'components/organisms/UserFormDrawer';
import React, {
  createContext,
  useContext,
  ReactNode,
  useCallback,
  useState,
} from 'react';

type Drawers =
  | 'OptionsDrawer'
  | 'UserFormDrawer'
  | 'CatalogDrawer'
  | 'ProductDrawer'
  | 'BagDrawer';

type DrawerContextData = {
  currentDrawer?: string;
  isThisDrawer: (drawer: Drawers) => boolean;
  openDrawer: (value: Drawers) => void;
  closeDrawers: () => void;
};

type DrawerProviderProps = {
  children: ReactNode;
};

const DrawerContext = createContext<DrawerContextData>({} as DrawerContextData);

export const DrawerProvider = ({ children }: DrawerProviderProps) => {
  const [currentDrawer, setCurrentDrawer] = useState<Drawers | undefined>();

  const isThisDrawer = useCallback(
    (drawer: Drawers) => {
      return currentDrawer === drawer;
    },
    [currentDrawer],
  );

  const openDrawer = useCallback((drawer: Drawers) => {
    setCurrentDrawer(drawer);
  }, []);

  const closeDrawers = useCallback(() => {
    setCurrentDrawer(undefined);
  }, []);

  return (
    <DrawerContext.Provider
      value={{ currentDrawer, isThisDrawer, openDrawer, closeDrawers }}
    >
      {children}
      <OptionsDrawer />
      <UserFormDrawer />
      <CatalogDrawer />
      <ProductDrawer />
      <BagDrawer />
    </DrawerContext.Provider>
  );
};

export function useDrawer(): DrawerContextData {
  const context = useContext(DrawerContext);

  if (!context) {
    throw new Error('useDrawer must be uses whithin a DrawerProvider');
  }

  return context;
}
