import { ReactNode } from 'react';
import { Container } from './styles';

interface TextProps {
  weight?: number;
  size?: number;
  align?: 'left' | 'right' | 'center';
  color?: 'textPrimary' | 'textSecondary';
  component?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span';
  whiteSpace?: 'nowrap' | 'normal' | 'pre' | 'pre-wrap' | 'pre-line';
  gutterBottom?: number;
  children: ReactNode;
  title?: string;
}

export const Text = ({ children, component, ...rest }: TextProps) => {
  return (
    <Container as={component ?? 'p'} {...rest}>
      {children}
    </Container>
  );
};
