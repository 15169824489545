import { motion } from 'framer-motion';
import { useCallback, useMemo } from 'react';
import { LikeIconStyled } from './styles';

type AnimatedIconProps = {
  onAnimationComplete?: () => void;
};

export const AnimatedIcon = ({ onAnimationComplete }: AnimatedIconProps) => {
  const getRandomNumber = useCallback((min: number, max: number) => {
    return Math.random() * (max - min) + min;
  }, []);

  const animate = useMemo(
    () => ({
      opacity: [0, 0.9, 0],
      y: [getRandomNumber(0, 100), -200, -400],
      x: [
        getRandomNumber(-15, 15),
        getRandomNumber(-50, 15),
        getRandomNumber(-50, 15),
        getRandomNumber(-15, 15),
        getRandomNumber(-50, 15),
        getRandomNumber(-50, 15),
      ],
    }),
    [getRandomNumber],
  );

  const color = useMemo(() => {
    const colors = ['red', 'white', 'deepskyblue'];
    return colors[Math.floor(getRandomNumber(0, 2.9))];
  }, [getRandomNumber]);

  return (
    <motion.div
      animate={animate}
      transition={{
        duration: 5,
        ease: 'linear',
      }}
      style={{ position: 'absolute', right: 0, bottom: 0 }}
      onAnimationComplete={onAnimationComplete}
    >
      <LikeIconStyled color={color} />
    </motion.div>
  );
};
