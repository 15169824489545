import { ArrowIcon } from 'assets/icons';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;

  background: #f0f0f0;
  border-radius: 4px;
  padding: 8px;
  flex-direction: column;
`;

export const Header = styled.header`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;

  cursor: pointer;
`;

export const Content = styled.main`
  display: flex;
  flex-direction: column;

  padding: 8px;

  > * {
    margin-bottom: 10px;
  }
`;

export const ArrowIconStyled = styled(ArrowIcon)`
  > path {
    fill: #545454;
  }
  margin-right: 15px;
`;
