import styled from 'styled-components';

type ContainerProps = {
  top?: string;
  right?: string;
  bottom?: string;
  left?: string;
};

export const Container = styled.span<ContainerProps>`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  background: #e81a3f;
  color: white;
  font-size: 11px;
  padding: 0 5px;
  height: 20px;
  min-width: 20px;

  top: ${({ top }) => top};
  right: ${({ right }) => right};
  bottom: ${({ bottom }) => bottom};
  left: ${({ left }) => left};
`;
