import { InputNumber } from 'components/atoms/InputNumber';
import { SquareImage } from 'components/atoms/SquareImage';
import { Text } from 'components/atoms/Text';
import { ProductOptions } from '../ProductOptions';
import { SelectOptions } from '../SelectOptions';
import {
  Container,
  Content,
  ContentBody,
  ContentHeader,
  DeleteIconStyled,
} from './styles';

type BagCardProps = {
  title: string;
  price: string;
  imgUrl: string;
  removeFromBag: () => void;
};

export const BagCard = ({
  title,
  price,
  imgUrl,
  removeFromBag,
}: BagCardProps) => {
  return (
    <Container>
      <Content>
        <SquareImage url={imgUrl} alt="Produto" size={70} />
        <div>
          <ContentHeader>
            <Text size={12} weight={500}>
              {title}
            </Text>
            <DeleteIconStyled onClick={removeFromBag} />
          </ContentHeader>
          <ContentBody>
            <Text size={16} weight={700}>
              R$ {price}
            </Text>
            <InputNumber />
          </ContentBody>
        </div>
      </Content>
      <ProductOptions>
        <SelectOptions />
      </ProductOptions>
    </Container>
  );
};
