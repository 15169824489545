import { Text } from 'components/atoms/Text';
import {
  CloseIconStyled,
  AudioOnIconStyled,
  AudioOffIconStyled,
  Buttons,
  Container,
  RecIcon,
  Title,
} from './styles';

type PipHeaderProps = {
  muted: boolean;
  toggleMuted: () => void;
};

export const PipHeader = ({ muted, toggleMuted }: PipHeaderProps) => {
  return (
    <Container>
      <Title>
        <RecIcon
          initial={{ scale: 1 }}
          animate={{ scale: 1.2 }}
          transition={{
            duration: 0.5,
            repeat: Infinity,
            repeatType: 'reverse',
          }}
        />
        <Text color="textSecondary">Live</Text>
      </Title>

      <Buttons>
        <CloseIconStyled />
        {muted ? (
          <AudioOffIconStyled onClick={toggleMuted} />
        ) : (
          <AudioOnIconStyled onClick={toggleMuted} />
        )}
      </Buttons>
    </Container>
  );
};
