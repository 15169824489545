import Player from '@vimeo/player';
import ReactJWPlayer from 'react-jw-player';
import { useConfig } from 'context/Configs';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Players, VideoPlayers } from 'constants/enums';
import { Container, IFrame, IFrameBlocker } from './styles';

type LivePlayerProps = {
  muted?: boolean;
};

export const LivePlayer = ({ muted }: LivePlayerProps) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const vimeo = useRef<Player>();
  const { configData } = useConfig();
  const [isReady, setIsReady] = useState(false);

  const onReady = () => setIsReady(true);

  const url = useMemo(() => {
    const paramsBusca = new URL(configData.liveUrl);
    paramsBusca.searchParams.append('background', '1');
    return paramsBusca.toString();
  }, [configData.liveUrl]);

  useEffect(() => {
    if (iframeRef.current) {
      vimeo.current = new Player(iframeRef.current);
    }
  }, []);

  useEffect(() => {
    vimeo.current?.setMuted(muted || false);
  }, [muted]);

  useEffect(() => {
    if (configData.videoPlayerType === VideoPlayers.JWPLAYER) {
      if (!isReady || typeof window.jwplayer !== 'function') return;
      const player = window.jwplayer(Players.MAIN);
      player.setMute(muted);
    }
  }, [isReady, configData.videoPlayerType, muted]);

  const videoPlayers = {
    [VideoPlayers.JWPLAYER]: (
      <ReactJWPlayer
        className="react-jwp-player"
        playerId={Players.MAIN}
        playerScript="https://cdn.jwplayer.com/libraries/x5wBfnZp.js"
        file="https://stream.mux.com/xy00p9Ebu6jEHAwTLRyYPGJC8AX402QrkTqWLpJUr7NfU.m3u8"
        onReady={onReady}
        aspectRatio="9:16"
        customProps={{ stretching: 'fill' }}
        isAutoPlay
      />
    ),
    [VideoPlayers.VIMEO]: (
      <IFrame
        ref={iframeRef}
        title="vimeo player"
        src={url}
        frameBorder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowFullScreen
      />
    ),
  };

  return (
    <Container isPip={configData.isPip}>
      {videoPlayers[configData.videoPlayerType]}
      <IFrameBlocker isPip={configData.isPip} />
    </Container>
  );
};
