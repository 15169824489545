import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 10px;

  height: 36px;
  background: rgba(0, 0, 0, 0.2);
  border: 0.5px solid white;
  box-sizing: border-box;
  border-radius: 45px;
  padding: 5px 15px;

  > label {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;

    position: relative;
    > span {
      position: absolute;
      left: 0;
    }

    > input {
      width: 100%;
      background: transparent;
      border: none;
      outline: none;
      color: ${({ theme }) => theme.palette.text.secondary};
      font-family: ${({ theme }) => theme.typography.fontFamily};
    }
  }

  > svg {
    cursor: pointer;
  }
`;

export const ButtonInput = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  background: transparent;
  border: 0;
  cursor: pointer;
`;
