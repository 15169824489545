import { gql, useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { IProduct, IProductSave } from 'types/products';

export type GetProductsVars = {
  liveId: string;
};

export type GetProductsSaveVars = {
  userId: string;
};

export const useQueryCatalog = (liveId: string) => {
  const GET_PRODUCTS = gql`
    query Query($data: CatalogByLive!) {
      getCatalogbyLive(data: $data) {
        data {
          product {
            id
            name
            description
            category {
              name
            }
            price
            size
            image
            gallery {
              image
            }
            status
          }
        }
      }
    }
  `;

  const { data } = useQuery<
    { getCatalogbyLive: { data: IProduct[] } } | undefined,
    { data: GetProductsVars }
  >(GET_PRODUCTS, {
    variables: {
      data: { liveId },
    },
  });

  return data?.getCatalogbyLive.data || [];
};

export const useQueryProductByLive = (liveId: string) => {
  const GET_PRODUCTS = gql`
    query Query($data: ProductsByLive!) {
      getProductsbyLive(data: $data) {
        data {
          id
          product {
            id
            name
            enterpriseId
            description
            category {
              name
              description
            }
            price
            size
            image
            gallery {
              image
            }
            status
            url
            sku
            productid
          }
          liveId
          avaliable
        }
      }
    }
  `;

  const { data, refetch } = useQuery<
    { getProductsbyLive: { data: IProduct[] } } | undefined,
    { data: GetProductsVars }
  >(GET_PRODUCTS, {
    variables: {
      data: { liveId },
    },
  });

  const productsDisplayed = useMemo(() => {
    if (!data?.getProductsbyLive.data) {
      return [];
    }
    return data.getProductsbyLive.data.filter(
      (releasedProduct) => releasedProduct.avaliable,
    );
  }, [data]);

  return { productsDisplayed, refetch };
};

export const useQueryProductsSave = (userId: string | undefined) => {
  const GET_PRODUCTS_SAVED = gql`
    query Query($data: ProductsByUser!) {
      getProductsSave(data: $data) {
        data {
          id
          product {
            id
          }
        }
      }
    }
  `;

  const { data } = useQuery<
    { getProductsSave: { data: IProductSave[] } },
    { data: GetProductsSaveVars }
  >(GET_PRODUCTS_SAVED, {
    variables: {
      data: { userId: userId ?? '' },
    },
  });

  const productsSaved = useMemo(() => {
    if (!data?.getProductsSave.data) {
      return [];
    }
    return data.getProductsSave.data;
  }, [data]);

  return { productsSaved };
};
