import { InputHTMLAttributes, forwardRef, useState, ForwardedRef } from 'react';
import { Container, Field, Label } from './styles';

export type TextFieldProps = {
  label?: string;
  type?: 'text' | 'tel' | 'email';
} & InputHTMLAttributes<HTMLInputElement>;

export const TextField = forwardRef(
  (
    { name, type, disabled, label, value, required, ...rest }: TextFieldProps,
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    const [isFocused, setIsFocused] = useState(false);

    const handleFocus = () => {
      setIsFocused(true);
    };

    const handleBlur = () => {
      setIsFocused(false);
    };

    const hasValue = Boolean(value);

    return (
      <Container onFocus={handleFocus} onBlur={handleBlur}>
        <Field
          type={type ?? 'text'}
          ref={ref}
          id={name}
          name={name}
          disabled={disabled}
          value={value}
          required={required}
          {...rest}
        />
        {!!label && (
          <Label htmlFor={name} $isFocused={isFocused} $hasValue={hasValue}>
            {label}
            {required && ' *'}
          </Label>
        )}
      </Container>
    );
  },
);
