import { useCallback, useEffect, useRef, useState } from 'react';
import { Chat } from 'components/molecules/Chat';
import { InputChat } from 'components/atoms/InputChat';
import { ProductSlider } from 'components/molecules/ProductSlider';
import { useDrawer } from 'context/Drawer';
import { useUser } from 'context/User';
import { useConfig } from 'context/Configs';
import { FloatingHeart } from 'components/molecules/FloatingHeart';
import { useChat } from 'context/Chat';
import {
  CatalogIconStyled,
  ChatContainer,
  Container,
  ShareIconStyled,
} from './styles';

export function Content() {
  const [isFocused, setIsFocused] = useState(false);
  const [text, setText] = useState('');
  const chatContainerRef = useRef<HTMLElement>(null);

  const { openDrawer } = useDrawer();
  const { user } = useUser();
  const { configData } = useConfig();
  const { messages, sendMessage } = useChat();

  const handleFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  const handleoptions = useCallback(() => {
    openDrawer('OptionsDrawer');
  }, [openDrawer]);

  const handleTextChange = useCallback((value: string) => {
    setText(value);
  }, []);

  const handleSubmit = () => {
    if (!user) {
      openDrawer('UserFormDrawer');
      return;
    }
    sendMessage(text);
    setText('');
  };

  const handleShare = async () => {
    const paramsBusca = new URL(window.location.href);
    if (user?.id) {
      paramsBusca.searchParams.append('ref', user?.id);
    }
    const shareData: ShareData = {
      title: 'OLI',
      text: 'Oi! Assista a essa live comigo e aproveite para comprar produtos com condições exclusivas!\n',
      url: paramsBusca.toString(),
    };
    if (navigator.share) {
      await navigator.share(shareData);
    } else {
      navigator.clipboard.writeText(
        `${shareData.title} - ${shareData.text} ${shareData.url}`,
      );
    }
  };

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current?.scrollHeight;
    }
  }, [messages]);

  return (
    <Container>
      {configData.showChat && (
        <ChatContainer ref={chatContainerRef}>
          {messages.map((message) => (
            <Chat
              key={message.id}
              name={message.name}
              text={message.content}
              isAdmin={[
                'c7618478-3efa-4d2a-8e93-bbb514826022',
                '8ceccecd-2aaa-4a24-8ef0-26dcc450f7f6',
              ].includes(message.userId)}
            />
          ))}
        </ChatContainer>
      )}
      <ProductSlider />
      <div>
        {!isFocused && (
          <CatalogIconStyled onClick={() => openDrawer('CatalogDrawer')} />
        )}
        <InputChat
          onFocus={handleFocus}
          onBlur={handleBlur}
          onOptions={handleoptions}
          onSubmit={handleSubmit}
          onChangeText={handleTextChange}
          value={text}
        />
        {!isFocused && <ShareIconStyled onClick={handleShare} />}
        <FloatingHeart />
      </div>
    </Container>
  );
}
