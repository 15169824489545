import styled from 'styled-components';

type SquareImageProps = {
  size: number;
};

export const Container = styled.img<SquareImageProps>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  border-radius: 4px;
  object-fit: fill;
`;
