import { Text } from 'components/atoms/Text';
import { Button, Container } from './styles';

type PipContentProps = {
  togglePip: () => void;
};

export const PipContent = ({ togglePip }: PipContentProps) => {
  return (
    <Container>
      <Button onClick={togglePip}>
        <Text color="textSecondary">Participe!</Text>
      </Button>
    </Container>
  );
};
