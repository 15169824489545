import { DefaultTheme } from 'styled-components';

export const theme: DefaultTheme = {
  typography: {
    fontFamily: ['Poppins', 'sans-serif'].join(','),
    fontSize: 11,
  },
  palette: {
    primary: {
      main: '#262626',
    },
    text: {
      primary: '#545454',
      secondary: '#FFFFFFE5',
    },
  },
};
