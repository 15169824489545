import styled from 'styled-components';

export const Container = styled.section`
  display: flex;
  justify-content: center;

  z-index: 1;
`;

export const Button = styled.button`
  border: none;
  padding: 10px;
  border-radius: 4px;
  color: #fff;
  background-color: #e10050;
  text-transform: uppercase;
`;
