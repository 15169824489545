import styled from 'styled-components';

type ContainerProps = {
  isPip: boolean;
};

export const Container = styled.div<ContainerProps>`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 0;
  background: #000;
  border-radius: ${({ isPip }) => (isPip ? '10px' : null)};

  #player-tm1 .jw-controlbar {
    display: none;
  }

  .react-jwp-player {
    height: 100%;
    display: flex;
  }

  .react-jwp-player #player-tm1 {
    max-width: 100%;
    border-radius: ${({ isPip }) => (isPip ? '10px' : null)};
  }

  @media (max-width: 768px) {
    .react-jwp-player #player-tm1 {
      max-width: 100vw;
    }
  }
`;

export const IFrameBlocker = styled.div<ContainerProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-box-shadow: inset 0px -300px 145px -70px rgba(50, 50, 50, 0.5),
    inset 0px 65px 65px -20px rgba(50, 50, 50, 0.5);
  -moz-box-shadow: inset 0px -300px 145px -70px rgba(50, 50, 50, 0.5),
    inset 0px 65px 65px -20px rgba(50, 50, 50, 0.5);
  box-shadow: inset 0px -300px 145px -70px rgba(50, 50, 50, 0.5),
    inset 0px 65px 65px -20px rgba(50, 50, 50, 0.5);

  border-radius: ${({ isPip }) => (isPip ? '10px' : null)};
`;

export const IFrame = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
`;
