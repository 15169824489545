import { DeleteIcon } from 'assets/icons';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  background: #ffffff;
  border: 0.5px solid #d7d7d7;
  border-radius: 4px;
  margin-bottom: 10px;
`;

export const Content = styled.div`
  display: flex;

  padding: 10px;

  > img {
    margin-right: 10px;
  }

  > div {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
`;

export const ContentHeader = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > p {
    flex: 3;
  }
`;

export const ContentBody = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > * {
    flex: 1;
  }
`;

export const DeleteIconStyled = styled(DeleteIcon)`
  flex: 1;
`;
