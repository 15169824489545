import { ButtonHTMLAttributes } from 'react';
import styled, { css } from 'styled-components';

type IVariants = 'contained' | 'outlined';

type ButtonProps = {
  variant?: IVariants;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const variants = (variant: IVariants | undefined) => {
  switch (variant) {
    default:
    case 'contained':
      return css`
        border: none;
        background-color: ${({ theme }) => theme.palette.primary.main};
        color: ${({ theme }) => theme.palette.text.secondary};
      `;
    case 'outlined':
      return css`
        border: 1px solid ${({ theme }) => theme.palette.primary.main};
        background-color: transparent;
        color: ${({ theme }) => theme.palette.text.primary};
      `;
  }
};

const disabledVariant = () => {
  return css`
    color: rgba(0, 0, 0, 0.26);
    border: none;
    background-color: #0000001f;
    cursor: not-allowed;
  `;
};

export const Container = styled.button<ButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  border-radius: 4px;

  ${({ variant }) => variants(variant)}
  ${({ disabled }) => disabled && disabledVariant()}
`;
