import { VisibleIcon } from 'assets/icons';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Item = styled.button`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  background: transparent;
  border: none;
  padding: 20px;
`;

export const VisibleIconStyled = styled(VisibleIcon)`
  width: 22.99px;
  height: 15px;
  margin-right: 10px;
  > path {
    fill: ${({ theme }) => theme.palette.text.primary};
  }
`;
