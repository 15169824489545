import { gql, useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { ChatMessage } from 'types/chat';

export type GetChatHistoryVars = {
  chatId: string;
};

export const useQueryChatHistory = (chatId: string) => {
  const GET_CHAT_HISTORY = gql`
    query QueryChat($data: SearchChat) {
      getChatElements(data: $data) {
        data {
          id
          name
          chatId
          content
          userId
          createdAt
          updatedAt
        }
      }
    }
  `;

  const { data } = useQuery<
    { getChatElements: { data: ChatMessage[] } } | undefined,
    { data: GetChatHistoryVars }
  >(GET_CHAT_HISTORY, {
    variables: {
      data: { chatId },
    },
  });

  const chatHistory = useMemo(
    () => data?.getChatElements.data || [],
    [data?.getChatElements.data],
  );

  return chatHistory;
};
