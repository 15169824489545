import { Text } from 'components/atoms/Text';
import { Drawer } from 'components/molecules/Drawer';
import { useConfig } from 'context/Configs';
import { useDrawer } from 'context/Drawer';
import { Container, Item, VisibleIconStyled } from './styles';

export const OptionsDrawer = () => {
  const { isThisDrawer, closeDrawers } = useDrawer();
  const { toggleChat, configData } = useConfig();
  return (
    <Drawer
      isOpen={isThisDrawer('OptionsDrawer')}
      onClose={() => {
        closeDrawers();
      }}
      fitContent
    >
      <Container>
        <Item
          onClick={() => {
            toggleChat();
          }}
        >
          <VisibleIconStyled />
          <Text size={14}>
            {configData.showChat
              ? 'Esconder comentários'
              : 'Mostrar comentários'}
          </Text>
        </Item>
      </Container>
    </Drawer>
  );
};
