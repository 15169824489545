import styled from 'styled-components';

export const Container = styled.div`
  display: flex;

  background: #f0f0f0;
  border-radius: 4px;
  padding: 8px;

  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;

  > div {
    flex: 1;
  }
`;
