import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  position: relative;
  height: 240px;
  width: 135px;
  padding: 10px;
  border-radius: 4px;
  background: transparent;
`;

export const AnimationContainer = styled(motion.div)`
  height: 240px;
  width: 135px;
  position: absolute;
  bottom: 20px;
  right: 10px;
`;
