import {
  createContext,
  useContext,
  ReactNode,
  useState,
  useCallback,
  useEffect,
} from 'react';
import { gql, useQuery } from '@apollo/client';
import { addToSDK } from 'utils/sdkBuilder';
import { VideoPlayers } from 'constants/enums';
import { TEMP_WS_CHAT_ID } from 'constants/temp';

type IConfigData = {
  isPip: boolean;
  liveId: string;
  showChat: boolean;
  liveUrl: string;
  videoPlayerType: VideoPlayers;
};

type ConfigContextData = {
  configData: IConfigData;
  togglePip: (override?: boolean) => void;
  toggleChat: () => void;
  isOpen: boolean;
  enterpriseId: string | undefined;
  setEnterpriseId: React.Dispatch<React.SetStateAction<string | undefined>>;
};

type ConfigProviderProps = {
  children: ReactNode;
};

const ConfigContext = createContext<ConfigContextData>({} as ConfigContextData);

export const ConfigProvider = ({ children }: ConfigProviderProps) => {
  const [enterpriseId, setEnterpriseId] = useState<string>();
  const [isOpen, setIsOpen] = useState(false);

  const [configData, setConfigData] = useState<IConfigData>({
    isPip: true,
    liveId: TEMP_WS_CHAT_ID,
    showChat: true,
    liveUrl: 'https://vimeo.com/event/1424902/embed',
    videoPlayerType: VideoPlayers.JWPLAYER,
  });

  const togglePip = useCallback((override?: boolean) => {
    setConfigData((state) => ({
      ...state,
      isPip: override ?? !state.isPip,
    }));
  }, []);

  const toggleChat = useCallback(() => {
    setConfigData((state) => ({
      ...state,
      showChat: !state.showChat,
    }));
  }, []);

  const GET_LIVE = gql`
    query Query($getLiveByUserData: LiveSearchByUser!) {
      getLiveByUser(data: $getLiveByUserData) {
        data {
          id
        }
      }
    }
  `;

  const { data } = useQuery(GET_LIVE, {
    variables: {
      getLiveByUserData: {
        userId: '',
      },
    },
  });

  useEffect(() => {
    if (data?.getLiveByUser.data) {
      const [firstUser] = data.getLiveByUser.data;
      if (firstUser) {
        setConfigData((state) => ({
          ...state,
          liveId: firstUser.id || '',
        }));
      }
    }
  }, [data]);

  useEffect(() => {
    addToSDK({
      open: () => {
        setIsOpen(true);
      },
      close: () => {
        setIsOpen(false);
      },
    });
  }, []);

  return (
    <ConfigContext.Provider
      value={{
        configData,
        togglePip,
        toggleChat,
        isOpen,
        enterpriseId,
        setEnterpriseId,
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
};

export function useConfig(): ConfigContextData {
  const context = useContext(ConfigContext);

  if (!context) {
    throw new Error('useConfig must be uses whithin a DrawerProvider');
  }

  return context;
}
