import { Drawer } from 'components/molecules/Drawer';
import { Text } from 'components/atoms/Text';
import { useDrawer } from 'context/Drawer';
import { useProduct } from 'context/Products';
import { useState } from 'react';
import { SquareImage } from 'components/atoms/SquareImage';
import { NotSavedIcon, SavedIcon } from 'assets/icons';
import {
  Card,
  Container,
  List,
  ButtonCard,
  CardTop,
  CardBottom,
  CardContent,
  Filter,
  CategoryButton,
} from './styles';

export const CatalogDrawer = () => {
  const [selectedCategory, setSelectedCategory] = useState('Todos');

  const { isThisDrawer, closeDrawers, openDrawer } = useDrawer();
  const { products, setCurrentProduct, savedProducts, toggleSaved } =
    useProduct();

  const categories = products.reduce(
    (pv, cv) => {
      const { name } = cv.product.category;

      if (!pv.includes(name)) {
        return [...pv, cv.product.category.name];
      }
      return pv;
    },
    ['Todos'],
  );

  const productsByCategory = products.filter((product) => {
    if (selectedCategory === 'saved') {
      const hasProduct = savedProducts.some(
        (savedProduct) => savedProduct.product.id === product.product.id,
      );

      if (hasProduct) {
        return true;
      }
      return false;
    }
    if (selectedCategory === 'Todos') {
      return true;
    }
    return product.product.category.name === selectedCategory;
  });

  return (
    <Drawer
      isOpen={isThisDrawer('CatalogDrawer')}
      onClose={() => {
        closeDrawers();
      }}
      maxHeight="50vh"
    >
      <Container>
        <Filter>
          <CategoryButton
            isSelected={selectedCategory === 'saved'}
            onClick={() => {
              setSelectedCategory('saved');
            }}
          >
            <SavedIcon />
            Salvo
          </CategoryButton>
          {categories.map((category) => (
            <CategoryButton
              key={category}
              isSelected={selectedCategory === category}
              onClick={() => {
                setSelectedCategory(category);
              }}
            >
              {category}
            </CategoryButton>
          ))}
        </Filter>
        <List>
          {productsByCategory.map((value) => (
            <Card
              key={value.product.id}
              onClick={() => {
                setCurrentProduct(value);
                openDrawer('ProductDrawer');
              }}
            >
              <SquareImage
                url={value.product.image}
                size={70}
                alt="Imagem Produto"
              />
              <CardContent>
                <CardTop>
                  <Text size={12} weight={500}>
                    {value.product.name}
                  </Text>

                  {savedProducts.some(
                    (savedProduct) =>
                      savedProduct.product.id === value.product.id,
                  ) ? (
                    <SavedIcon
                      onClick={(event) => {
                        event.stopPropagation();
                        toggleSaved(value.product.id);
                      }}
                    />
                  ) : (
                    <NotSavedIcon
                      onClick={(event) => {
                        event.stopPropagation();
                        toggleSaved(value.product.id);
                      }}
                    />
                  )}
                </CardTop>
                <CardBottom>
                  <Text size={16} weight={700}>
                    R${value.product.price}
                  </Text>
                  <ButtonCard>
                    <Text size={12} weight={500} color="textSecondary">
                      Eu quero
                    </Text>
                  </ButtonCard>
                </CardBottom>
              </CardContent>
            </Card>
          ))}
        </List>
      </Container>
    </Drawer>
  );
};
