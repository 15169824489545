import styled from 'styled-components';

export const Container = styled.nav`
  display: flex;
  overflow: scroll;
  padding: 10px 0;
  -ms-overflow-style: none;
  scrollbar-width: none;

  & > div {
    margin-right: 10px;
  }
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Card = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;

  background-color: #fff;
  border-radius: 4px;

  min-width: 252.6px;
  max-width: 290px;
  height: 85px;
  padding: 7.5px;
  cursor: pointer;

  > img {
    max-width: 70px;
    max-height: 70px;
    margin-right: 25px;
  }

  > div {
    margin-bottom: 7px;

    > h2 {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
`;
