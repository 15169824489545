export type initializeOptions = {
  enterpriseId: string;
};

interface CustomWindow extends Window {
  oli: {
    initialize?: (options: initializeOptions) => void;
    open?: () => void;
    close?: () => void;
  };
}
declare let window: CustomWindow;

export function addToSDK(params: CustomWindow['oli']) {
  window.oli = { ...window.oli, ...params };
}
