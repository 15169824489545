import styled from 'styled-components';

type ContainerProps = {
  size: number;
  src?: string;
};

export const Container = styled.div<ContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  border-radius: 50%;
  overflow: hidden;
`;

export const Image = styled.img`
  height: 100%;
  width: 100%;
`;
