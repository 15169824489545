import styled from 'styled-components';

type ContainerProps = {
  isAdmin?: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  padding-top: 10px;
  padding-right: 25%;

  p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    color: ${({ isAdmin }) => isAdmin && '#FFD595E5'};
  }
`;

export const TitleContainer = styled.div`
  display: flex;
`;
