import { useCallback, useMemo, useState } from 'react';
import { PipContent } from 'components/molecules/PipContent';
import { PipHeader } from 'components/molecules/PipHeader';
import { LivePlayer } from 'components/atoms/LivePlayer';
import { useConfig } from 'context/Configs';
import { useWindowSize } from 'hooks';
import { AnimationContainer, Container } from './styles';

export const Pip = () => {
  const [muted, setMuted] = useState(true);
  const { height, width } = useWindowSize();
  const { togglePip } = useConfig();

  const toggleMute = useCallback(() => {
    setMuted((state) => !state);
  }, []);

  const handlePip = useCallback(() => {
    togglePip();
  }, [togglePip]);

  const dragConstraints = useMemo(
    () => ({
      right: 0,
      bottom: 0,
      top: -(height - 270),
      left: -(width - 160),
    }),
    [height, width],
  );

  return (
    <AnimationContainer
      drag
      whileDrag={{ scale: 1.2 }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      dragConstraints={dragConstraints}
      onDoubleClick={handlePip}
    >
      <Container>
        <LivePlayer muted={muted} />
        <PipHeader muted={muted} toggleMuted={toggleMute} />
        <PipContent togglePip={handlePip} />
      </Container>
    </AnimationContainer>
  );
};
