import { ButtonHTMLAttributes } from 'react';
import styled from 'styled-components';

interface CategotyButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  isSelected: boolean;
}

export const Container = styled.div``;

export const CategoryButton = styled.button<CategotyButtonProps>`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  white-space: nowrap;
  cursor: pointer;
  padding: 6px 10px;
  background: #ffffff;
  border: 1px solid #dcdedf;
  border-color: ${({ isSelected }) => (isSelected ? '#262626' : '#dcdedf')};
  border-radius: 6px;

  > svg {
    margin-right: 5px;
    height: 15px;
  }
`;

export const Filter = styled.div`
  display: flex;
  overflow: auto;
  align-items: center;

  padding: 10px 0;
  position: sticky;
  top: 0;
  background-color: white;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  > button {
    margin-right: 6px;
  }
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

export const Card = styled.div`
  display: flex;

  min-height: 85px;
  background: #ffffff;
  border: 0.5px solid #d7d7d7;
  border-radius: 4px;
  margin-bottom: 10px;
  padding: 8px;
  cursor: pointer;

  > img {
    margin: auto;
  }
`;

export const CardContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-left: 10px;
`;

export const CardTop = styled.div`
  display: flex;
  flex: 1;

  > p {
    width: 75%;
  }

  > svg {
    margin-left: auto;
  }
`;

export const CardBottom = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: flex-end;
`;

export const ButtonCard = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  padding: 4px 12px;
  border: none;
  height: 24px;
  background: #00b695;
  border-radius: 4px;
  cursor: pointer;
`;
