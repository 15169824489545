import { useCallback, useEffect, useRef, useState } from 'react';
import { io, Socket } from 'socket.io-client';
import { AnimatedIcon } from 'components/atoms/AnimatedIcon';
import { useConfig } from 'context/Configs';
import { useUser } from 'context/User';
import { usePageVisibility } from 'hooks/PageVisibility';
import { Container, LikeIconStyled } from './styles';

export const FloatingHeart = () => {
  const [hearts, setHearts] = useState<number[]>([]);
  const socket = useRef<Socket>();
  const channel = useRef('');
  const { isPageVisible } = usePageVisibility();
  const { configData } = useConfig();
  const { user } = useUser();

  const addLikes = useCallback(() => {
    socket.current?.emit('new-like', {
      liveId: channel.current,
      userId: user?.id ?? '',
    });
  }, [user?.id]);

  const createHeart = useCallback(() => {
    setHearts((state) => {
      const index = state.length;
      return [...state, index];
    });
  }, []);

  const removeHeart = useCallback((id: number) => {
    setHearts((state) => state.filter((heart) => heart !== id));
  }, []);

  useEffect(() => {
    socket.current = io(process.env.REACT_APP_IP_CHAT ?? '', {
      path: '/likes',
      transports: ['websocket'],
      auth: {
        accessToken: user?.id ?? '',
      },
      query: { eventId: configData.liveId },
    });

    socket.current?.on('connect', () => {
      socket.current?.on('connected', (res: { liveId: string }) => {
        channel.current = res.liveId;
        // socket.current?.on('error', (err) => console.error(err));
      });
      // socket.current?.on('error', (err) => console.error(err));
    });

    return () => {
      socket.current?.close();
    };
  }, [configData.liveId, user?.id]);

  useEffect(() => {
    if (isPageVisible) {
      socket.current?.on('share-like', () => {
        createHeart();
      });
      socket.current?.on('share-to-all', () => {
        createHeart();
      });
    }
    return () => {
      socket.current?.removeAllListeners('share-to-all');
      socket.current?.removeAllListeners('share-like');
    };
  }, [createHeart, isPageVisible]);

  return (
    <Container>
      <LikeIconStyled onClick={addLikes} />
      {hearts.map((value) => (
        <AnimatedIcon
          key={value}
          onAnimationComplete={() => removeHeart(value)}
        />
      ))}
    </Container>
  );
};
