import { Text } from 'components/atoms/Text';
import { useState } from 'react';
import { ButtonMinus, ButtonPlus, Container, DisplayNumber } from './styles';

export const InputNumber = () => {
  const [value, setValue] = useState(1);

  const decrement = () => {
    setValue((state) => state - 1);
  };

  const increment = () => {
    setValue((state) => state + 1);
  };

  return (
    <Container>
      <ButtonMinus onClick={decrement}>
        <Text size={19} weight={500}>
          -
        </Text>
      </ButtonMinus>
      <DisplayNumber>
        <Text size={12} weight={500}>
          {value}
        </Text>
      </DisplayNumber>
      <ButtonPlus onClick={increment}>
        <Text size={19} weight={500}>
          +
        </Text>
      </ButtonPlus>
    </Container>
  );
};
