import { useState, useEffect } from 'react';

type PageVisibility = {
  isPageVisible: boolean;
};

export const usePageVisibility = (): PageVisibility => {
  const [isPageVisible, setIsPageVisible] = useState(
    document.visibilityState === 'visible',
  );

  useEffect(() => {
    const handleVisibility = () => {
      setIsPageVisible(document.visibilityState === 'visible');
    };

    window.addEventListener('visibilitychange', handleVisibility);
    return () => {
      window.removeEventListener('visibilitychange', handleVisibility);
    };
  }, []);

  return { isPageVisible };
};
