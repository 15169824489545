import {
  createContext,
  useContext,
  ReactNode,
  useState,
  SetStateAction,
  Dispatch,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { IProduct, IProductSave } from 'types/products';
import {
  useQueryCatalog,
  useQueryProductByLive,
  useQueryProductsSave,
} from 'graphql/queries/products';
import { useMutationSavedProducts } from 'graphql/mutations/products';
import { io, Socket } from 'socket.io-client';
import { useUser } from './User';
import { useConfig } from './Configs';

type ProductContextData = {
  products: IProduct[];
  productsDisplayed: IProduct[];
  currentProduct: IProduct | undefined;
  setCurrentProduct: Dispatch<SetStateAction<IProduct | undefined>>;
  savedProducts: IProductSave[];
  toggleSaved: (id: string) => void;
  bag: IProduct[];
  addToBag: (productId: string) => void;
  removeFromBag: (productId: string) => void;
};

type ProductProviderProps = {
  children: ReactNode;
};

const ProductContext = createContext<ProductContextData>(
  {} as ProductContextData,
);

export const ProductProvider = ({ children }: ProductProviderProps) => {
  const [currentProduct, setCurrentProduct] = useState<IProduct>();
  const [bag, setBag] = useState<IProduct[]>([]);
  const [savedProducts, setSavedProducts] = useState<IProductSave[]>([]);
  const socket = useRef<Socket>();
  const { user } = useUser();
  const { configData } = useConfig();
  const products = useQueryCatalog(configData.liveId);
  const { productsDisplayed, refetch } = useQueryProductByLive(
    configData.liveId,
  );
  const { productsSaved } = useQueryProductsSave(user?.id);
  const { addSavedProducts, deleteSavedProducts } = useMutationSavedProducts();

  const addToBag = useCallback(
    (productId: string) => {
      const findedProduct = [...products, ...productsDisplayed].find(
        (product) => product.product.id === productId,
      );
      if (findedProduct) {
        setBag((state) => [...state, { ...findedProduct }]);
      }
    },
    [products, productsDisplayed],
  );

  const removeFromBag = useCallback(
    (productId: string) => {
      const bagWithRemovedProduct = bag.filter(
        (product) => product.product.id !== productId,
      );

      setBag([...bagWithRemovedProduct]);
    },
    [bag],
  );

  const toggleSaved = useCallback(
    async (productId: string) => {
      const hasProduct = savedProducts.find(
        (savedProduct) => savedProduct.product.id === productId,
      );

      if (hasProduct) {
        const savedProductsFiltered = savedProducts.filter(
          (value) => value.product.id !== productId,
        );
        setSavedProducts(savedProductsFiltered);
        deleteSavedProducts({
          variables: {
            data: {
              id: hasProduct.id,
            },
          },
        });
      } else {
        const { data } = await addSavedProducts({
          variables: {
            data: {
              liveId: configData.liveId,
              userId: user?.id || '',
              productId,
            },
          },
        });
        if (data) {
          setSavedProducts((state) => [...state, data.createProductSave.data]);
        }
      }
    },
    [
      addSavedProducts,
      configData.liveId,
      deleteSavedProducts,
      savedProducts,
      user?.id,
    ],
  );

  useEffect(() => {
    setSavedProducts(productsSaved);
  }, [productsSaved]);

  useEffect(() => {
    socket.current = io(process.env.REACT_APP_IP_CHAT ?? '', {
      path: '/products',
      transports: ['websocket'],
      auth: {
        accessToken: user?.id ?? '',
      },
      query: { eventId: configData.liveId },
    });

    socket.current?.on('connect', () => {
      socket.current?.on('connected', () => {
        socket.current?.on('update-to-all', () => {
          refetch();
        });

        // socket.current?.on('error', (err) => console.error(err));
      });
      // socket.current?.on('error', (err) => console.error(err));
    });

    return () => {
      socket.current?.close();
    };
  }, [configData.liveId, refetch, user?.id]);

  return (
    <ProductContext.Provider
      value={{
        products,
        productsDisplayed,
        currentProduct,
        setCurrentProduct,
        bag,
        addToBag,
        removeFromBag,
        savedProducts,
        toggleSaved,
      }}
    >
      {children}
    </ProductContext.Provider>
  );
};

export function useProduct(): ProductContextData {
  const context = useContext(ProductContext);

  if (!context) {
    throw new Error('useProduct must be uses whithin a ProductProvider');
  }

  return context;
}
