import { Button } from 'components/atoms/Button';
import { Drawer } from 'components/molecules/Drawer';
import { ProductOptions } from 'components/molecules/ProductOptions';
import { Text } from 'components/atoms/Text';
import { useDrawer } from 'context/Drawer';
import { SelectOptions } from 'components/molecules/SelectOptions';
import { ProductQuantity } from 'components/molecules/ProductQuantity';
import { useMemo, useState } from 'react';
import { useProduct } from 'context/Products';
import { SquareImage } from 'components/atoms/SquareImage';
import { useWindowSize } from 'hooks';
import {
  ButtonsContainer,
  Container,
  ImageSlideContainer,
  NotSavedIconStyled,
  SavedIconStyled,
} from './styles';

export const ProductDrawer = () => {
  const { isThisDrawer, closeDrawers, openDrawer } = useDrawer();
  const { currentProduct, addToBag, savedProducts, toggleSaved } = useProduct();
  const [snapedPoint, setSnapedPoint] = useState(0);
  const { height } = useWindowSize();

  const snapPoints = useMemo(
    () => [Number((height * 0.8).toFixed()), Number((height * 0.5).toFixed())],
    [height],
  );

  const handleAddBag = () => {
    if (currentProduct) {
      addToBag(currentProduct.product.id);
      openDrawer('BagDrawer');
    }
  };

  return (
    <Drawer
      initialSnap={1}
      snapPoints={snapPoints}
      isOpen={isThisDrawer('ProductDrawer')}
      onClose={() => {
        closeDrawers();
      }}
      onSnap={(index) => {
        setSnapedPoint(snapPoints[index]);
      }}
    >
      <Container height={snapedPoint - 40}>
        <ImageSlideContainer>
          {currentProduct?.product.gallery.map(({ image }) => (
            <SquareImage url={image} size={145} alt="Imagem do produto " />
          ))}
        </ImageSlideContainer>
        <Text size={12} weight={500}>
          {currentProduct?.product.name}
        </Text>
        <Text size={14} weight={700}>
          R$ {currentProduct?.product.price}
        </Text>
        <ProductOptions>
          <SelectOptions />
          <ProductQuantity />
        </ProductOptions>
        {snapedPoint === snapPoints[0] && (
          <>
            <Text size={12} weight={500}>
              Descrição
            </Text>
            <Text component="span" size={11} weight={400}>
              {currentProduct?.product.description}
            </Text>
            <Text size={12} weight={500}>
              Informações
            </Text>
            <Text component="span" size={11} weight={400}>
              {currentProduct?.product.description}
            </Text>
          </>
        )}
        <ButtonsContainer>
          <Button
            variant="outlined"
            onClick={() => {
              if (currentProduct) {
                toggleSaved(currentProduct.product.id);
              }
            }}
          >
            {savedProducts.some(
              (savedProduct) =>
                savedProduct.product.id === currentProduct?.product.id || '',
            ) ? (
              <SavedIconStyled />
            ) : (
              <NotSavedIconStyled />
            )}
          </Button>
          <Button onClick={handleAddBag}>Adicionar ao Carrinho</Button>
        </ButtonsContainer>
      </Container>
    </Drawer>
  );
};
