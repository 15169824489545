import { OptionsIcon } from 'assets/icons';
import { Text } from 'components/atoms/Text';
import { ChangeEvent, KeyboardEvent, useRef, useState } from 'react';
import { useOnClickOutside } from 'hooks';
import { ButtonInput, Container } from './styles';

type InputChatProps = {
  onFocus?: () => void;
  onBlur?: () => void;
  onOptions?: () => void;
  onSubmit?: (text: string) => void;
  onChangeText?: (text: string) => void;
  value: string;
};

export const InputChat = ({
  onFocus,
  onBlur,
  onOptions,
  onSubmit,
  onChangeText,
  value,
}: InputChatProps) => {
  const [isFocused, setIsFocused] = useState(false);
  const refContainer = useRef<HTMLDivElement>(null);

  const hasValue = Boolean(value);

  const handleFocus = () => {
    setIsFocused(true);
    onFocus?.();
  };

  const handleBlur = () => {
    setIsFocused(false);
    onBlur?.();
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChangeText?.(event.target.value);
  };

  const handleSubmit = () => {
    if (value) {
      onSubmit?.(value);
    }
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  useOnClickOutside(refContainer, handleBlur);

  return (
    <Container ref={refContainer}>
      <label htmlFor="chat">
        {!(isFocused || hasValue) && (
          <Text component="span" color="textSecondary">
            Diga Algo...
          </Text>
        )}
        <input
          onFocus={handleFocus}
          onKeyPress={handleKeyDown}
          onChange={handleChange}
          value={value}
          id="chat"
          type="text"
          autoCorrect="off"
          autoComplete="off"
          maxLength={220}
        />
      </label>
      {isFocused ? (
        <ButtonInput onClick={handleSubmit}>
          <Text component="span" color="textSecondary">
            Publicar
          </Text>
        </ButtonInput>
      ) : (
        <ButtonInput
          onClick={() => {
            onOptions?.();
          }}
        >
          <OptionsIcon />
        </ButtonInput>
      )}
    </Container>
  );
};
