import { AudioOffIcon, AudioOnIcon, BagIcon, PipIcon } from 'assets/icons';
import { HTMLMotionProps, motion } from 'framer-motion';
import { isMobile } from 'react-device-detect';

import styled from 'styled-components';

interface CotainerProps extends HTMLMotionProps<'div'> {
  height: number;
  width: number;
}

export const Wrapper = styled(motion.div)<CotainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;
  top: 0;
  right: 0;

  height: ${({ height }) => height}px;
  width: ${({ width }) => width}px;
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.55);
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  position: relative;
  padding: 20px;
  overflow: hidden;
  height: ${() => (isMobile ? '100%' : '90%')};
  width: ${() => (isMobile ? '100%' : '600px')};
  border-radius: ${() => (isMobile ? 'none' : '10px')};

  > header {
    display: flex;

    align-items: center;

    & h1 {
      padding: 0 10px;
    }

    > div:last-child {
      display: flex;
      margin-left: auto;
      > * {
        margin-left: 20px;
      }
    }
  }
`;

export const BagWrapper = styled.div`
  position: relative;
`;

export const PipIconStyled = styled(PipIcon)`
  width: 24.03px;
  height: 20px;
  cursor: pointer;
`;

export const BagIconStyled = styled(BagIcon)`
  height: 22px;
  cursor: pointer;
`;

export const AudioOffIconStyled = styled(AudioOffIcon)`
  width: 17px;
  height: 22px;
  cursor: pointer;
`;

export const AudioOnIconStyled = styled(AudioOnIcon)`
  width: 17px;
  height: 22px;
  cursor: pointer;
`;
