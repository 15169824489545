import styled from 'styled-components';

type ButtonProps = {
  isSelected?: boolean;
};

export const Container = styled.div`
  flex: 1;
  display: flex;
  align-items: center;

  padding: 10px;
  background: #dcdedf;
  border-radius: 4px;

  overflow: hidden;

  > div {
    display: flex;
    overflow: scroll;

    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const Button = styled.button<ButtonProps>`
  padding: 6px 10px;
  background: #ffffff;
  border: 1px solid ${(props) => (props.isSelected ? '#262626' : '#dcdedf')};
  border-radius: 6px;
  font-weight: 600;
  font-size: 14px;
  margin: 0 5px;
  cursor: pointer;
`;
