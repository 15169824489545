import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;

  border-radius: 4px;
  border: 1px solid #d7d7d7;
  background: #ffffff;
`;

export const ButtonMinus = styled.button`
  flex: 1;
  display: flex;
  justify-content: center;

  background: transparent;
  border: 0;
  border-right: 1px solid #d7d7d7;
  cursor: pointer;
`;

export const ButtonPlus = styled.button`
  flex: 1;
  display: flex;
  justify-content: center;

  background: transparent;
  border: 0;
  border-left: 1px solid #d7d7d7;
  cursor: pointer;
`;

export const DisplayNumber = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default;
`;
