import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import { ReactNode } from 'react';
import { ThemeProvider } from 'styled-components';
import { theme } from 'styles/theme';
import { ConfigProvider } from './Configs';
import { DrawerProvider } from './Drawer';
import { UserProvider } from './User';
import { ProductProvider } from './Products';
import { ChatProvider } from './Chat';

type AppProviderProps = {
  children: ReactNode;
};

const client = new ApolloClient({
  uri: process.env.REACT_APP_IP_GRAPHQL ?? '',
  cache: new InMemoryCache(),
});

export const AppProvider = ({ children }: AppProviderProps) => {
  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <ConfigProvider>
          <UserProvider>
            <ProductProvider>
              <DrawerProvider>
                <ChatProvider>{children}</ChatProvider>
              </DrawerProvider>
            </ProductProvider>
          </UserProvider>
        </ConfigProvider>
      </ThemeProvider>
    </ApolloProvider>
  );
};
