import {
  createContext,
  useContext,
  ReactNode,
  useCallback,
  useState,
} from 'react';
import { IUser } from 'types/user';
import { useMutationsUsers } from 'graphql/mutations/users';

type UserContextData = {
  createUser: (user: IUser) => void;
  user: IUser | undefined;
};

type UserProviderProps = {
  children: ReactNode;
};

const UserContext = createContext<UserContextData>({} as UserContextData);

export const UserProvider = ({ children }: UserProviderProps) => {
  const [user, setUser] = useState<IUser | undefined>(() => {
    const localStorageUser = localStorage.getItem('@oli:user');
    if (localStorageUser) {
      return JSON.parse(localStorageUser);
    }
    return undefined;
  });
  const { addUser } = useMutationsUsers();

  const createUser = useCallback(
    async (userData: IUser) => {
      try {
        const data = await addUser({ data: userData });
        setUser(data);
        localStorage.setItem('@oli:user', JSON.stringify(data));
      } catch (error) {
        // console.error(error);
      }
    },
    [addUser],
  );

  return (
    <UserContext.Provider value={{ user, createUser }}>
      {children}
    </UserContext.Provider>
  );
};

export function useUser(): UserContextData {
  const context = useContext(UserContext);

  if (!context) {
    throw new Error('useUser must be uses whithin a UserProvider');
  }

  return context;
}
