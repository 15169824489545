import { ReactNode } from 'react';
import { SheetProps } from 'react-modal-sheet/dist/types';
import { SheetCustom } from './styles';

interface DrawerProps extends SheetProps {
  children: ReactNode;
  maxHeight?: string;
  fitContent?: boolean;
}

export const Drawer = ({
  children,
  maxHeight,
  fitContent,
  onClose,
  ...rest
}: DrawerProps) => {
  return (
    <SheetCustom
      $maxHeight={maxHeight}
      $fitContent={fitContent}
      onClose={onClose}
      {...rest}
      id="oli-sheet"
    >
      <SheetCustom.Container>
        <SheetCustom.Header />
        <SheetCustom.Content>{children}</SheetCustom.Content>
      </SheetCustom.Container>
      <SheetCustom.Backdrop
        onTap={() => {
          onClose();
        }}
      />
    </SheetCustom>
  );
};
