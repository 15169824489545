import { Container } from './styles';

interface SquareImageProps {
  size: number;
  url: string;
  alt: string;
}

export const SquareImage = ({ size, url, alt }: SquareImageProps) => {
  return (
    <Container src={url} alt={alt} size={size} width={size} height={size} />
  );
};
