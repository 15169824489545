import { AdminIcon } from 'assets/icons';
import { Text } from 'components/atoms/Text';
import { Container, TitleContainer } from './styles';

type ChatProps = {
  name: string;
  text: string;
  isAdmin?: boolean;
};

export const Chat = ({ name, text, isAdmin }: ChatProps) => {
  const textLimited = text.substring(0, 219);

  return (
    <Container title={text} isAdmin={isAdmin}>
      <TitleContainer>
        {isAdmin && <AdminIcon />}
        <Text weight={500} color="textSecondary">
          {name}:
        </Text>
      </TitleContainer>
      <Text color="textSecondary">{textLimited}</Text>
    </Container>
  );
};
