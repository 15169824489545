import { ReactNode } from 'react';
import styled from 'styled-components';

interface TextProps {
  weight?: number;
  size?: number;
  align?: 'left' | 'right' | 'center';
  color?: 'textPrimary' | 'textSecondary';
  whiteSpace?: 'nowrap' | 'normal' | 'pre' | 'pre-wrap' | 'pre-line';
  children: ReactNode;
  gutterBottom?: number;
}

export const Container = styled.div<TextProps>`
  font-weight: ${({ weight }) => weight};
  font-size: ${({ size, theme }) => size ?? theme.typography.fontSize}px;
  font-family: ${({ theme }) => theme.typography.fontFamily};
  text-align: ${({ align }) => align};
  color: ${({ color, theme }) => {
    if (color === 'textPrimary') {
      return theme.palette.text.primary;
    }
    if (color === 'textSecondary') {
      return theme.palette.text.secondary;
    }
    return color ?? theme.palette.text.primary;
  }};
  margin: 0;
  margin-bottom: ${({ gutterBottom }) => gutterBottom}px;
  white-space: ${({ whiteSpace }) => whiteSpace};
`;
