import { Button } from 'components/atoms/Button';
import { Text } from 'components/atoms/Text';
import { BagCard } from 'components/molecules/BagCard';
import { Drawer } from 'components/molecules/Drawer';
import { ProductFooter } from 'components/molecules/ProductFooter';
import { useDrawer } from 'context/Drawer';
import { useProduct } from 'context/Products';
import { useCallback } from 'react';
import { BagBlackIconStyled, EmptyContainer } from './styles';

export const BagDrawer = () => {
  const { isThisDrawer, closeDrawers } = useDrawer();
  const { bag, removeFromBag } = useProduct();

  const totalPrice = bag.reduce((pv, cv) => {
    const str = cv.product.price;
    const num = Number(str.replace(',', '.'));

    return pv + num;
  }, 0);

  const handleContinueShopping = useCallback(() => {
    closeDrawers();
  }, [closeDrawers]);

  return (
    <Drawer
      isOpen={isThisDrawer('BagDrawer')}
      onClose={() => {
        closeDrawers();
      }}
      maxHeight="50vh"
      fitContent
    >
      {bag.length ? (
        <>
          {bag.map((productBag) => (
            <BagCard
              key={productBag.product.id}
              title={productBag.product.name}
              price={productBag.product.price}
              imgUrl={productBag.product.image}
              removeFromBag={() => {
                removeFromBag(productBag.product.id);
              }}
            />
          ))}
          <ProductFooter
            amount={bag.length}
            totalPrice={totalPrice}
            continueShopping={handleContinueShopping}
          />
        </>
      ) : (
        <EmptyContainer>
          <BagBlackIconStyled />
          <Text component="h1" weight={600} size={12} gutterBottom={10}>
            Adicione produtos à sacola
          </Text>
          <Text weight={400} size={14} gutterBottom={25} whiteSpace="pre-line">
            Veja os produtos do catálogo e {'\n'} adicione à sacola para
            comprar.
          </Text>
          <Button variant="outlined">
            <Text size={12} color="textPrimary">
              Ver catálogo
            </Text>
          </Button>
        </EmptyContainer>
      )}
    </Drawer>
  );
};
