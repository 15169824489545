import { Content } from 'components/organisms/Content';
import { Text } from 'components/atoms/Text';
import { useConfig } from 'context/Configs';
import { useWindowSize } from 'hooks';
import { LivePlayer } from 'components/atoms/LivePlayer';
import { useCallback, useMemo, useState } from 'react';
import { useDrawer } from 'context/Drawer';
import { Avatar } from 'components/atoms/Avatar';
import { Badge } from 'components/atoms/Badge';
import { useProduct } from 'context/Products';
import {
  AudioOffIconStyled,
  AudioOnIconStyled,
  BagIconStyled,
  BagWrapper,
  Container,
  Overlay,
  PipIconStyled,
  Wrapper,
} from './styles';

export const Main = () => {
  const { height, width } = useWindowSize();
  const { togglePip } = useConfig();
  const { openDrawer } = useDrawer();
  const { bag } = useProduct();
  const [muted, setMuted] = useState(false);

  const toggleMute = useCallback(() => {
    setMuted((state) => !state);
  }, []);

  const bagSize = useMemo(() => {
    if (bag.length > 99) {
      return '+99';
    }
    return String(bag.length);
  }, [bag.length]);

  const badgeEnable = Boolean(bagSize.length);

  return (
    <Wrapper
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      height={height}
      width={width}
    >
      <Overlay />
      <Container>
        <LivePlayer muted={muted} />
        <header style={{ zIndex: 1 }}>
          <Avatar
            src="https://pbs.twimg.com/profile_images/877163870451888128/2nkmJD15_400x400.jpg"
            size={32}
          />
          <Text weight={600} color="textSecondary" component="h1">
            Pernambucanas Live
          </Text>
          <div>
            {muted ? (
              <AudioOffIconStyled onClick={toggleMute} />
            ) : (
              <AudioOnIconStyled onClick={toggleMute} />
            )}
            <PipIconStyled
              onClick={() => {
                togglePip();
              }}
            />
            <BagWrapper>
              {!badgeEnable && (
                <Badge top="-10px" right="-10px">
                  {bagSize}
                </Badge>
              )}
              <BagIconStyled
                onClick={() => {
                  openDrawer('BagDrawer');
                }}
              />
            </BagWrapper>
          </div>
        </header>
        <Content />
      </Container>
    </Wrapper>
  );
};
