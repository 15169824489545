import { Text } from 'components/atoms/Text';
import { useState } from 'react';
import { Container, Button } from './styles';

export const SelectOptions = () => {
  const [selectedValue, setSelectedValue] = useState<number>();
  return (
    <Container>
      <Text size={11} weight={500}>
        Tamanho
      </Text>
      <div>
        {[35, 36, 37, 38, 39, 40].map((value) => (
          <Button
            onClick={() => {
              setSelectedValue(value);
            }}
            isSelected={selectedValue === value}
          >
            {value}
          </Button>
        ))}
      </div>
    </Container>
  );
};
