import { ButtonHTMLAttributes, ReactNode } from 'react';
import { Container } from './styles';

type ButtonProps = {
  children?: ReactNode;
  variant?: 'contained' | 'outlined';
} & ButtonHTMLAttributes<HTMLButtonElement>;

export const Button = ({ children, variant, ...rest }: ButtonProps) => {
  return (
    <Container variant={variant} {...rest}>
      {children}
    </Container>
  );
};
