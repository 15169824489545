import { gql, useMutation } from '@apollo/client';
import { IProductSave } from 'types/products';

export type SetSavedProductsVars = {
  liveId: string;
  productId: string;
  userId: string;
};

export type DeleteSavedProductsVars = {
  id: string;
};

export const useMutationSavedProducts = () => {
  const CREATE_SAVED_PRODUCTS = gql`
    mutation CreateProductSaveMutation($data: ProductSaveRegister!) {
      createProductSave(data: $data) {
        data {
          id
          product {
            id
          }
        }
      }
    }
  `;

  const DELETE_SAVED_PRODUCTS = gql`
    mutation DeleteProductSaveMutation($data: DeleteProductSave!) {
      deleteProductSave(data: $data) {
        data {
          id
        }
      }
    }
  `;

  const [addSavedProducts] = useMutation<
    { createProductSave: { data: IProductSave } },
    { data: SetSavedProductsVars }
  >(CREATE_SAVED_PRODUCTS);

  const [deleteSavedProducts] = useMutation<
    null,
    { data: DeleteSavedProductsVars }
  >(DELETE_SAVED_PRODUCTS);

  return { addSavedProducts, deleteSavedProducts };
};
