import { Text } from 'components/atoms/Text';
import { ReactNode, useState } from 'react';
import { ArrowIconStyled, Container, Content, Header } from './styles';

type ProductOptionsProps = {
  children: ReactNode;
};

export const ProductOptions = ({ children }: ProductOptionsProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Container>
      <Header
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <div>
          <Text size={11} weight={500}>
            Escolher Opções
          </Text>
          <Text size={9} weight={500}>
            Por favor, selecione uma opção
          </Text>
        </div>
        <ArrowIconStyled
          style={{
            transform: `rotate(${!isOpen ? 90 : 270}deg)`,
            transition: 'all .5s ease 0s',
          }}
        />
      </Header>

      {isOpen && <Content>{children}</Content>}
    </Container>
  );
};
