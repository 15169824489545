import { NotSavedIcon, SavedIcon } from 'assets/icons';
import styled from 'styled-components';

type ContainerProps = {
  height: number;
};

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  height: ${({ height }) => height}px;
`;

export const ButtonsContainer = styled.div`
  display: flex;

  & button:last-child {
    margin-left: 5px;
    flex: 1;
  }
`;

export const ImageSlideContainer = styled.div`
  overflow: scroll;
  display: flex;
  margin-bottom: 10px;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  > * {
    margin-right: 10px;
  }
`;

export const SavedIconStyled = styled(SavedIcon)``;

export const NotSavedIconStyled = styled(NotSavedIcon)``;
