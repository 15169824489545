import { LikeFilledIcon } from 'assets/icons';
import styled from 'styled-components';

interface LikeIconStyled {
  color: string;
}

export const LikeIconStyled = styled(LikeFilledIcon)<LikeIconStyled>`
  width: 22.62px;
  height: 19.1px;
  > path {
    fill: ${({ color }) => color};
  }
`;
