import { useQueryChatHistory } from 'graphql/queries/chatHistory';
import {
  createContext,
  useContext,
  ReactNode,
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import { io, Socket } from 'socket.io-client';
import { ChatMessage } from 'types/chat';
import { useConfig } from './Configs';
import { useUser } from './User';

type ChatContextData = {
  messages: Array<ChatMessage>;
  sendMessage: (content: string) => void;
};

type ChatProviderProps = {
  children: ReactNode;
};

const ChatContext = createContext<ChatContextData>({} as ChatContextData);

export const ChatProvider = ({ children }: ChatProviderProps) => {
  const [messages, setMessages] = useState<Array<ChatMessage>>([]);
  const socket = useRef<Socket>();
  const [chatId, setchatId] = useState('');
  const { user } = useUser();
  const { configData } = useConfig();
  const oldMessages = useQueryChatHistory(chatId);

  useEffect(() => {
    setMessages((state) => [...state, ...oldMessages]);
  }, [oldMessages]);

  useEffect(() => {
    const socketConnection = io(process.env.REACT_APP_IP_CHAT ?? '', {
      path: '/chat',
      transports: ['websocket'],
      auth: {
        accessToken: user?.id ?? '',
      },
      query: { eventId: configData.liveId },
    });
    socket.current = socketConnection;

    socket.current?.on('connect', () => {
      socket.current?.on('connected', (res: { chatId: string }) => {
        setchatId(res.chatId);
        socket.current?.on('message_sended', (msg: ChatMessage) => {
          setMessages((state) => [...state, msg]);
        });
        socket.current?.on('new_message', (msg: ChatMessage) => {
          setMessages((state) => [...state, msg]);
        });
        // socket.current?.on('error', (err) => console.error(err));
      });
      // socket.current?.on('error', (err) => console.error(err));
    });

    return () => {
      socketConnection.close();
    };
  }, [configData.liveId, user?.id]);

  const messagesSorted = useMemo(
    () =>
      messages.sort((a, b) => {
        if (new Date(a.createdAt) < new Date(b.createdAt)) {
          return -1;
        }
        if (new Date(a.createdAt) > new Date(b.createdAt)) {
          return 1;
        }
        return 0;
      }),
    [messages],
  );

  const sendMessage = useCallback(
    (content: string) => {
      socket.current?.emit('send_message', {
        chatId,
        content,
      });
    },
    [chatId],
  );

  return (
    <ChatContext.Provider value={{ messages: messagesSorted, sendMessage }}>
      {children}
    </ChatContext.Provider>
  );
};

export function useChat(): ChatContextData {
  const context = useContext(ChatContext);

  if (!context) {
    throw new Error('useChat must be uses whithin a ChatProvider');
  }

  return context;
}
