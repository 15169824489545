import { LabelHTMLAttributes } from 'react';
import styled, { css } from 'styled-components';

type LabelProps = {
  $isFocused: boolean;
  $hasValue: boolean;
} & LabelHTMLAttributes<HTMLLabelElement>;

export const Container = styled.div`
  display: flex;
  position: relative;
`;

export const Label = styled.label<LabelProps>`
  position: absolute;
  transition: all 0.2s;
  top: 25%;
  left: 10px;
  padding: 0 5px;
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-weight: 500;

  ${({ $isFocused, $hasValue }) =>
    ($isFocused || $hasValue) &&
    css`
      top: -6px;
      left: 10px;
      background: white;
      font-size: 11px;
    `}
  color: ${({ $isFocused }) => $isFocused && '#a6836d'};
`;

export const Field = styled.input`
  flex: 1;
  height: 50px;
  padding: 10px;
  border: 1px solid #54545499;
  border-radius: 4px;
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-weight: 400;
  color: ${({ theme }) => theme.palette.text.primary};

  outline: none;
  &:focus {
    border: 1px solid #a6836d;
  }
`;
