import Sheet from 'react-modal-sheet';
import { SheetProps } from 'react-modal-sheet/dist/types';
import styled from 'styled-components';

interface SheetCustomProps extends SheetProps {
  $maxHeight?: string;
  $fitContent?: boolean;
}

export const SheetCustom = styled(Sheet)<SheetCustomProps>`
  .react-modal-sheet-backdrop {
    border: none;
  }
  .react-modal-sheet-container {
    max-width: 600px;
    padding: 0 10px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 10px;
    max-height: ${({ $maxHeight }) => $maxHeight};
    height: ${({ $fitContent }) =>
      $fitContent ? 'auto !important' : undefined};
  }
  .react-modal-sheet-header {
    /* custom styles */
  }
  .react-modal-sheet-drag-indicator {
    /* custom styles */
  }
  .react-modal-sheet-content {
    touch-action: auto !important;

    /* custom styles */
  }
`;
