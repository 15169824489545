import { ReactNode } from 'react';
import { Container } from './styles';

type BadgeProps = {
  children?: ReactNode;
  top?: string;
  right?: string;
  bottom?: string;
  left?: string;
};

export const Badge = ({ children, top, right, bottom, left }: BadgeProps) => {
  return (
    <Container top={top} right={right} bottom={bottom} left={left}>
      {children}
    </Container>
  );
};
