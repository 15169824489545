import { Button } from 'components/atoms/Button';
import { Text } from 'components/atoms/Text';
import { Container } from './styles';

type ProductFooterProps = {
  totalPrice: number;
  amount: number;
  continueShopping: () => void;
};

export const ProductFooter = ({
  totalPrice,
  amount,
  continueShopping,
}: ProductFooterProps) => {
  return (
    <Container>
      <div>
        <Text size={16} weight={700}>
          Total:
          {totalPrice.toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL',
          })}
        </Text>
        <Text size={12} weight={500}>
          {amount > 1 ? `${amount} produtos` : `${amount} produto`}
        </Text>
      </div>
      <Button onClick={continueShopping}>Continuar compra</Button>
    </Container>
  );
};
